import React from "react";
import VideoItem from "./VideoItem";
import videoList from "./videoList";
import VideoCard from "./VideoCard";
import Menu from "./Menu";
//import Buttons from "./Buttons";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      videos: videoList,
      playList: "grace",
      videoCardsHidden: false,
      chosenVideo: "suugspQBO2Vg",
      chosenTheme: "Фаиз"
    };
    this.handleClick = this.handleClick.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
  }

  //changes the playlist to val
  //changes the theme title to valT (Tajik)
  //if a button is clicked then the video cards should be shown
  handleClick(val, valT) {
    this.setState({ playList: val });
    if (this.state.videoCardsHidden) {
      this.setState({ videoCardsHidden: false });
    }
    console.log(valT);
    this.setState({ chosenTheme: valT });
    this.className = "clickedButton";
  }

  //checked. This does change chosenVideo to id
  //if you console.log it doesn't show the change
  //this is because console.log is asynchronous
  //and it is called before state is changed
  loadVideo(id) {
    console.log(id);
    this.setState({
      videoCardsHidden: !this.state.videoCardsHidden,
      chosenVideo: id
    });
  }
  render() {
    //make an array of the videos in chosen category
    const filterVideos = this.state.videos.filter(vid =>
      vid.subject.includes(this.state.playList)
    );
    //console.log(filterVideos);
    //map over array and make videoCards for each
    const filteredVideos = filterVideos.map(vid => (
      <VideoCard
        key={vid.id}
        name={vid.name}
        subject={vid.subject}
        description={vid.description}
        source={vid.source}
        onClick={() => this.loadVideo(vid.id)}
      />
    ));

    //find the one chosen video
    const myVideo = this.state.videos.find(
      vid => vid.id === this.state.chosenVideo
    );
    console.log(myVideo);

    //todo: figure out how to use Buttons component with onclick
    //<Buttons onClick={this.handleClick} />

    return (
      <div>
        <div className="menu-div">
          <Menu />
        </div>
        <div className="top">
          <h1 className="title">Нигоҳ ба Китоб</h1>
        </div>
        <div className="main-app">
          <div className="buttonSections">
            <h1 class="button-heading">Мавзӯъ</h1>
            <div align="center" className="theme-buttons">
              <button
                onClick={() => this.handleClick("grace", "Файз")}
              >
                Файз
              </button>
              <button onClick={() => this.handleClick("prayer", "Дуо")}>
                Дуо
              </button>
              <button onClick={() => this.handleClick("parable", "Масалҳо")}>
                Масалҳо
              </button>
              <button
                onClick={() => this.handleClick("kingdom", "Подшоҳии Худованд")}
              >
                Подшоҳии Худованд
              </button>
            </div>
            <h1 class="button-heading">Китоб</h1>
            <div align="center" className="book-buttons">
              <button onClick={() => this.handleClick("Psalms", "Забур")}>
                Забур
              </button>
              <button onClick={() => this.handleClick("Isaiah", "Ишаъё")}>
                Ишаъё
              </button>
              <button onClick={() => this.handleClick("Matthew", "Матто")}>
                Матто
              </button>
              <button onClick={() => this.handleClick("Mark", "Марқус")}>
                Марқус
              </button>
              <button onClick={() => this.handleClick("Luke", "Луко")}>
                Луқо
              </button>
              <button onClick={() => this.handleClick("John", "Юҳанно")}>
                Юҳанно
              </button>
              <button
                onClick={() => this.handleClick("Acts", "Аъмоли Ҳаввориён")}
              >
                Аъмоли Ҳаввориён
              </button>
              <button onClick={() => this.handleClick("James", "Яъқуб")}>
                Яъқуб
              </button>
              <button onClick={() => this.handleClick("Peter", "Петрус")}>
                Петрус
              </button>
              <button onClick={() => this.handleClick("Romans", "Ба Румиён")}>
                Ба Румиён
              </button>
              <button
                onClick={() => this.handleClick("Philippians", "Ба Филиппиён")}
              >
                Ба Филиппиён
              </button>
              <button
                onClick={() => this.handleClick("Colossians", "Ба Қӯлассиён")}
              >
                Ба Қӯлассиён
              </button>
            </div>
          </div>
          <div className="chosenThemeDiv">
            <h1 className="chosenTheme">{this.state.chosenTheme}</h1>
          </div>
          <div className="displayVideos">
            {this.state.videoCardsHidden ? (
              <div>
                <VideoItem
                  source={myVideo.source}
                  id={myVideo.id}
                  name={myVideo.name}
                />
              </div>
            ) : null}
          </div>

          {this.state.videoCardsHidden ? null : (
            <div className="displayVideos">{filteredVideos} </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
