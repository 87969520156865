import React from "react";
//import kitobThumb from "../images/kitobThumb"

function VideoCard(props) {
  //
  return (
    <div className="card-div" onClick={props.onClick}>
      <h4>{props.name}</h4>
      <img src="kitobThumb.png" alt="videoPic" />
      
    </div>
  );
}

export default VideoCard;
