const allVideos = [
  {
    source: "https://www.youtube.com/embed/6-TK_PQlnaE",
    id: "6-TK_PQlnaE",
    name: "Матто 6:9-10",
    subject: ["kingdom", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/BJf6yXhMHYw",
    id: "BJf6yXhMHYw",
    name: "Забур 144:11-13",
    subject: ["kingdom", "Psalms"]
  },
  {
    source: "https://www.youtube.com/embed/FDrBo-fOO18",
    id: "FDrBo-fOO18",
    name: "Дониёл 3:33",
    subject: ["kingdom", "Daniel"]
  },
  {
    source: "https://www.youtube.com/embed/0CPPpdaMULo",
    id: "0CPPpdaMULo",
    name: "1 Петрус 1:13-16",
    subject: ["kingdom", "Peter"]
  },
  {
    source: "https://www.youtube.com/embed/G998_cO9AyA",
    id: "G998_cO9AyA",
    name: "Румиён 5:8-10",
    subject: ["kingdom", "Romans"]
  },
  {
    source: "https://www.youtube.com/embed/bPmrWv4equ4",
    id: "bPmrWv4equ4",
    name: "Подшоҳии Худованд",
    subject: ["kingdom"]
  },
  {
    source: "https://www.youtube.com/embed/OGmqLqKDvn8",
    id: "OGmqLqKDvn8",
    name: "Матто 13:47-48",
    subject: ["kingdom", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/gYtcsBbFPXQ",
    id: "gYtcsBbFPXQ",
    name: "Матто 13:31-32",
    subject: ["kingdom", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/jeChTNTol48",
    id: "jeChTNTol48",
    name: "Матто 8:11",
    subject: ["kingdom", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/5TDh8ORnqeo",
    id: "5TDh8ORnqeo",
    name: "Ба Филиппиён 2:5-11",
    subject: ["Jesus", "Paul", "Philippians"]
  },
  {
    source: "https://www.youtube.com/embed/OsmyfhDusqw",
    id: "OsmyfhDusqw",
    name: "Ба Рyмиён 5:1-5",
    subject: ["Romans"]
  },
  {
    source: "https://www.youtube.com/embed/W2e97HH3wwk",
    id: "W2e97HH3wwk",
    name: "2 Тимотиюс 3:16-17",
    subject: ["Timothy"]
  },
  {
    source: "https://www.youtube.com/embed/kZqzM2bNvaM",
    id: "kZqzM2bNvaM",
    name: "Забур 138:1-7",
    subject: ["Psalms"]
  },
  {
    source: "https://www.youtube.com/embed/cP2AW5_BEGc",
    id: "cP2AW5_BEGc",
    name: "Юҳанно 3:16-18",
    subject: ["John"]
  },
  {
    source: "https://www.youtube.com/embed/168gnArpi7s",
    id: "168gnArpi7s",
    name: "Ба Қӯлассеён 3:1-6",
    subject: ["Paul", "Colossians"]
  },
  {
    source: "https://www.youtube.com/embed/tkhRiWI7Rss",
    id: "tkhRiWI7Rss",
    name: "1 Юҳанно 1:5-7",
    subject: ["1John"]
  },
  {
    source: "https://www.youtube.com/embed/juApPOD71UM",
    id: "juApPOD71UM",
    name: "Аъмол 2:37-39",
    subject: ["Acts"]
  },
  {
    source: "https://www.youtube.com/embed/-6hRsTFOihI",
    id: "-6hRsTFOihI",
    name: "1 Юҳанно 1:1-4",
    subject: ["1John"]
  },
  {
    source: "https://www.youtube.com/embed/9Iq2tD_3jYA",
    id: "9Iq2tD_3jYA",
    name: "Ба Рyмиён 12:1-2",
    subject: ["Romans"]
  },
  {
    source: "https://www.youtube.com/embed/DwvKIYfB3hM",
    id: "DwvKIYfB3hM",
    name: "Аъмол 3:1-6",
    subject: ["Acts"]
  },
  {
    source: "https://www.youtube.com/embed/WJd4yA4hlJI",
    id: "WJd4yA4hlJI",
    name: "1 Қӯринтиён 15:1-4",
    subject: ["Paul", "Corinthians"]
  },
  {
    source: "https://www.youtube.com/embed/6IW8rMtEGoE",
    id: "6IW8rMtEGoE",
    name: "Юҳанно 15:5-8",
    subject: ["John"]
  },
  {
    source: "https://www.youtube.com/embed/c9Tj7hEi67g",
    id: "c9Tj7hEi67g",
    name: "Юҳанно 6:67-69",
    subject: ["John"]
  },
  {
    source: "https://www.youtube.com/embed/XodPYlOhugk",
    id: "XodPYlOhugk",
    name: "Матто 20:29-34",
    subject: ["Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/t02R3zj5H7w",
    id: "t02R3zj5H7w",
    name: "Матто 9:20-22",
    subject: ["Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/tgArS7CeEzY",
    id: "tgArS7CeEzY",
    name: "Ба Қӯлассеён 1:19-23",
    subject: ["Paul", "Colossians"]
  },
  {
    source: "https://www.youtube.com/embed/BWMXtmbHg3M",
    id: "BWMXtmbHg3M",
    name: "Юҳанно 1:29-34",
    subject: ["John"]
  },
  {
    source: "https://www.youtube.com/embed/kXEa65uayFg",
    id: "kXEa65uayFg",
    name: "Матто 25:41-46",
    subject: ["grace", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/zcJzEg0_bHs",
    id: "zcJzEg0_bHs",
    name: "Матто 25:31-40",
    subject: ["grace", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/ktkvEyEcEGc",
    id: "ktkvEyEcEGc",
    name: "Ба Эфсусиён 1:13-14",
    subject: ["grace", "Paul", "Ephesians"]
  },
  {
    source: "https://www.youtube.com/embed/-yoGheWwFsw",
    id: "-yoGheWwFsw",
    name: "Ба Ибриён 11:1,6",
    subject: ["grace", "Hebrews"]
  },
  {
    source: "https://www.youtube.com/embed/ZkLm-t_dLvY",
    id: "ZkLm-t_dLvY",
    name: "Юханно 10:28-30",
    subject: ["grace", "John"]
  },
  {
    source: "https://www.youtube.com/embed/LIQ1spfv7ls",
    id: "LIQ1spfv7ls",
    name: "Аъмол 17:26-27",
    subject: ["grace", "Acts"]
  },
  {
    source: "https://www.youtube.com/embed/rBMNvR22Ezg",
    id: "rBMNvR22Ezg",
    name: "Юҳанно 3:16-18",
    subject: ["grace", "John"]
  },
  {
    source: "https://www.youtube.com/embed/uugspQBO2Vg",
    id: "uugspQBO2Vg",
    name: "Ба Румиён 10:9-10",
    subject: ["grace", "Romans"]
  },
  {
    source: "https://www.youtube.com/embed/_WCRLybCMtQ",
    id: "_WCRLybCMtQ",
    name: "Ба Румиён 3:23-26",
    subject: ["grace", "Romans"]
  },
  {
    source: "https://www.youtube.com/embed/86sbU1aGB2s",
    id: "86sbU1aGB2s",
    name: "Ба Румиён 3:21-23",
    subject: ["grace", "Romans"]
  },
  {
    source: "https://www.youtube.com/embed/U_i76qkG5qQ",
    id: "U_i76qkG5qQ",
    name: "Юханно 12:44-50",
    subject: ["grace", "John"]
  },
  {
    source: "https://www.youtube.com/embed/CjICEi63bvE",
    id: "CjICEi63bvE",
    name: "Аъмол 8:26-39",
    subject: ["grace", "Acts"]
  },
  {
    source: "https://www.youtube.com/embed/oI-5oj_45eY",
    id: "oI-5oj_45eY",
    name: "Юханно 12:39-40",
    subject: ["grace", "John"]
  },
  {
    source: "https://www.youtube.com/embed/e2WczyJN6Ro",
    id: "e2WczyJN6Ro",
    name: "Ишаъё 53:4-5",
    subject: ["grace", "Isaiah"]
  },
  {
    source: "https://www.youtube.com/embed/uqD27HzAmJs",
    id: "uqD27HzAmJs",
    name: "Марқус 1:14-15",
    subject: ["parable", "Mark"]
  },
  {
    source: "https://www.youtube.com/embed/MIZ4Ed536cw",
    id: "MIZ4Ed536cw",
    name: "Марқус 12:41-44",
    subject: ["parable", "Mark"]
  },
  {
    source: "https://www.youtube.com/embed/qhxroAFwV64",
    id: "qhxroAFwV64",
    name: "Матто 20:25-27",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/dM2fhhvEw2E",
    id: "dM2fhhvEw2E",
    name: "Луқо 11:5-10",
    subject: ["parable", "Luke"]
  },
  {
    source: "https://www.youtube.com/embed/d5pmAFO-xOc",
    id: "d5pmAFO-xOc",
    name: "Матто 13:3-9",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/-jxthfqb2zo",
    id: "-jxthfqb2zo",
    name: "Матто 7:13-14",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/l9GaT89hY88",
    id: "l9GaT89hY88",
    name: "Матто 5:13-16",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/O_fBC3KK_xc",
    id: "O_fBC3KK_xc",
    name: "Матто 5:1-5",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/Q9hhk3tNWfQ",
    id: "Q9hhk3tNWfQ",
    name: "Ишаъё 66:1-2",
    subject: ["kingdom", "Isaiah"]
  },
  {
    source: "https://www.youtube.com/embed/JXgM6UUFDpc",
    id: "JXgM6UUFDpc",
    name: "Матто 15:10-14",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/v51TfRn7j2g",
    id: "v51TfRn7j2g",
    name: "Луқо 13:6-9",
    subject: ["parable", "Luke"]
  },
  {
    source: "https://www.youtube.com/embed/Qm17M3dKaak",
    id: "Qm17M3dKaak",
    name: "Матто 7:24-29",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/a5cvhWB4SJE",
    id: "a5cvhWB4SJE",
    name: "Матто 21:28-32",
    subject: ["parable", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/rC1uowt-uG8",
    id: "rC1uowt-uG8",
    name: "1 Ретрус 5:6-11",
    subject: ["prayer", "Peter"]
  },
  {
    source: "https://www.youtube.com/embed/ORFMgTE4lTM",
    id: "ORFMgTE4lTM",
    name: "Ба Филиппиён 4:4-6",
    subject: ["prayer", "Paul", "Philippians"]
  },
  {
    source: "https://www.youtube.com/embed/8GqS4gAVgZg",
    id: "8GqS4gAVgZg",
    name: "Яъқуб 4:3",
    subject: ["prayer", "James"]
  },
  {
    source: "https://www.youtube.com/embed/DaufA7BfG9E",
    id: "DaufA7BfG9E",
    name: "Яъқуб 5:15",
    subject: ["prayer", "James"]
  },
  {
    source: "https://www.youtube.com/embed/4Jb0IO59kLk",
    id: "4Jb0IO59kLk",
    name: "Дуои Муштарак",
    subject: ["prayer"]
  },
  {
    source: "https://www.youtube.com/embed/oRMXxO8no4M",
    id: "oRMXxO8no4M",
    name: "1 Ба Тимотиюс 2:1-4",
    subject: ["prayer", "Timothy"]
  },
  {
    source: "https://www.youtube.com/embed/zi73gGf9W4E",
    id: "zi73gGf9W4E",
    name: "Матто 7:7-11",
    subject: ["prayer", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/e4vg6I58FEA",
    id: "e4vg6I58FEA",
    name: "Матто 6:9-13",
    subject: ["prayer", "Matthew"]
  },
  {
    source: "https://www.youtube.com/embed/psZcR4idQKM",
    id: "psZcR4idQKM",
    name: "Ба Филиппиён 4:6-7",
    subject: ["prayer", "Paul", "Philippians"]
  },
  {
    source: "https://www.youtube.com/embed/ETSl8gWsFZ0",
    id: "ETSl8gWsFZ0",
    name: "One Pound Fish",
    subject: ["fish"]
  }
];

export default allVideos;
