import React from "react";

function VideoItem(props) {
  //console.log(props.source);
  return (
    <div className="video-item">
      <h3>{props.item}</h3>
      <h4>{props.name}</h4>
      <iframe
        title={props.name}
        src={props.source}
        frameBorder="0"
        allow="accelerometer; autoplay; 
          encrypted-media; gyroscope; 
          picture-in-picture; fullscreen"
      />
      <hr />
    </div>
  );
}

export default VideoItem;
